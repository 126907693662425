import { template as template_11baa4a36544431fa2cf71836ee3d580 } from "@ember/template-compiler";
const EmptyState = template_11baa4a36544431fa2cf71836ee3d580(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
