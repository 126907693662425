import { template as template_71761a55621741f1bdf95acb47545718 } from "@ember/template-compiler";
const FKControlMenuContainer = template_71761a55621741f1bdf95acb47545718(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
