import { template as template_171cdb8ce9f54a45b9212a2959e00bf7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_171cdb8ce9f54a45b9212a2959e00bf7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
